import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import GameScreenshots from "../components/GameScreenshots"
import VideoSection from "../components/VideoSection"
import SiteImage from "../components/SiteImage"
import "../styles/scss/index.scss"
import "./index.scss"

export default function Home({
  data: {
    strapiHome: {
      seo,
      hero,
      featureList,
      propositionList,
      appLinks,
      clubContact,
    },
  },
}) {
  return (
    <Layout showNewsletterCTA>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname={"/"}
      />

      <section id="hero" className="hero is-medium">
        <div className="hero-body">
          <SiteImage
            image={hero.background}
            className="hero-background"
            alt=""
          />
          <div className="container">
            <div className="columns is-desktop">
              <div className="column is-7-desktop is-flex is-flex-direction-column is-align-items-center">
                <h1 className="mb-5 has-text-centered has-text-silver-gradient">
                  {/* <div className="title is-1">{hero.title}</div> */}
                  <div className="title is-2">{hero.subtitle}</div>
                </h1>
                <p className="has-text-centered">{hero.description}</p>
                <a
                  href={hero.link}
                  className="button is-gradient is-large is-family-secondary my-6 px-6 hero-desktop-cta"
                  target="_blank"
                  rel="noreferrer"
                >
                  {hero.linkTitle}
                </a>
                <div className="mt-6 hero-mobile-cta columns">
                  {appLinks.storeLinks.map(link => (
                    <a
                      key={link.id}
                      href={link.link}
                      className="column is-flex is-justify-content-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiteImage image={link.image} alt={link.title} />
                    </a>
                  ))}
              </div>
              </div>
              <div className="column is-5-desktop is-flex is-justify-content-center">
                <SiteImage
                  image={hero.heroImage}
                  objectFit={"contain"}
                  className="hero-image"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <VideoSection />
      <section id="feature-list" className="section">
        <div className="container py-6">
          <div className="is-flex is-align-items-center py-6">
            <div className="section-title-decoration mr-3"></div>
            <h3 className="section-title">{featureList.title}</h3>
          </div>
          <div className="columns">
            {featureList.feature.map(feature => (
              <div
                className="column is-4 is-flex is-flex-direction-column is-align-items-start"
                key={feature.id}
              >
                <SiteImage image={feature.logo} alt="" />
                <h4 className="my-6 title is-4">{feature.title}</h4>
                <p className="is-flex-grow-1">{feature.description}</p>
                {feature.link ? (
                  <a
                    className="button is-gradient is-rounded is-family-secondary my-6"
                    href={feature.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {feature.linkTitle}
                  </a>
                ) : (
                  <button className="button is-gradient is-rounded is-family-secondary my-6">
                    {feature.linkTitle}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="proposition-list" className="section">
        <div className="container py-6">
          <div className="is-flex is-align-items-center py-6">
            <div className="section-title-decoration mr-3"></div>
            <h3 className="section-title">{propositionList.title}</h3>
          </div>
          <div className="columns">
            {propositionList.proposition.map(proposition => (
              <div
                className="column is-3 is-flex is-flex-direction-column is-align-items-start py-6"
                key={proposition.id}
              >
                <SiteImage className="mb-6" image={proposition.logo} alt="" />
                <h4 className="title is-4 proposition-title">
                  {proposition.title}
                </h4>
                <p className="is-flex-grow-1">{proposition.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {clubContact && (
        <section id="club-contact" className="section">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column">
                <h4 className="title is-4">{clubContact.title}</h4>
                {clubContact.description && (
                  <p>{clubContact.description}</p>
                )}
                {clubContact.link.isInternalLink ? (
                  <Link
                    to={clubContact.link.link}
                    className="button px-6 my-6 is-rounded is-gradient"
                  >
                    {clubContact.link.title}
                  </Link>
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={clubContact.link.link}
                    className="button px-6 my-6 is-rounded is-gradient"
                  >
                    {clubContact.link.title}
                  </a>
                )}
              </div>
              {clubContact.image && (
                <div className="column is-narrow ml-6 is-hidden-mobile">
                  <SiteImage
                    width="298px"
                    height="450px"
                    image={clubContact.image}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
      <GameScreenshots />
      <section id="app-links" className="section">
        <div className="container py-6">
          <div className="columns">
            <div className="column is-flex is-flex-grow-1 is-flex-direction-column is-justify-content-center">
              <div className="title">{appLinks.title}</div>
              <div className="subtitle">{appLinks.subtitle}</div>
              <div className="is-flex">
                {appLinks.storeLinks.map(link => (
                  <a
                    key={link.id}
                    href={link.link}
                    className="mr-4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiteImage image={link.image} alt={link.title} />
                  </a>
                ))}
              </div>
            </div>
            <div className="column">
              <SiteImage image={appLinks.background} alt="" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiHome {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      hero {
        title
        subtitle
        description
        link
        linkTitle
        heroImage {
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        background {
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      featureList {
        title
        feature {
          id
          title
          description
          linkTitle
          link
          logo {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      propositionList {
        title
        proposition {
          id
          title
          description
          logo {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      appLinks {
        title
        subtitle
        background {
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        storeLinks {
          id
          title
          link
          image {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      clubContact {
        title
        description
        link {
          link
          isInternalLink
          title
        }
        image {
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
