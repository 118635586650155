import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Autoplay, EffectCards, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import SiteImage from "./SiteImage"
import "./GameScreenshots.scss"

function CloseIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="close-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM38.2678 12.2322C39.2441 13.2085 39.2441 14.7915 38.2678 15.7678L28.7855 25.25L38.2678 34.7322C39.2441 35.7085 39.2441 37.2915 38.2678 38.2678C37.2915 39.2441 35.7085 39.2441 34.7322 38.2678L25.25 28.7855L15.7678 38.2678C14.7915 39.2441 13.2085 39.2441 12.2322 38.2678C11.2559 37.2915 11.2559 35.7085 12.2322 34.7322L21.7145 25.25L12.2322 15.7678C11.2559 14.7915 11.2559 13.2085 12.2322 12.2322C13.2085 11.2559 14.7915 11.2559 15.7678 12.2322L25.25 21.7145L34.7322 12.2322C35.7085 11.2559 37.2915 11.2559 38.2678 12.2322Z"
        fill="white"
      />
    </svg>
  )
}

const SwiperComponent = ({ children, showCards, onTap }) =>
  showCards ? (
    <Swiper
      key={0}
      modules={[Autoplay, EffectCards, Pagination, Navigation]}
      slidesPerView={2}
      centeredSlides
      pagination={{
        clickable: true,
      }}
      navigation
      effect="cards"
      loop
      cardsEffect={{
        rotate: false,
        perSlideOffset: 40,
      }}
      autoplay={{
        pauseOnMouseEnter: true,
      }}
      onTap={onTap}
    >
      {children}
    </Swiper>
  ) : (
    <Swiper
      key={1}
      modules={[Autoplay, Pagination]}
      pagination={{
        clickable: true,
      }}
      loop
      autoplay
      spaceBetween={48}
    >
      {children}
    </Swiper>
  )

export default function GameScreenshots() {
  const {
    strapiHome: { gameScreenshots },
  } = useStaticQuery(graphql`
    {
      strapiHome {
        gameScreenshots {
          title
          description
          images {
            id
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const [showCards, setShowCards] = useState(false)
  const [expandedImageIndex, setExpandedImageIndex] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const onResize = () => {
      setShowCards(document.body.offsetWidth > 1024)
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  useEffect(() => {
    if (!showCards) {
      setExpandedImageIndex(null)
      setIsExpanded(false)
    }
  }, [showCards])

  return (
    gameScreenshots && (
      <section id="game-screenshots" className="section">
        <div className="container">
          <div className="is-flex is-align-items-center py-6">
            <div className="section-title-decoration mr-3"></div>
            <h3 className="section-title">{gameScreenshots.title}</h3>
          </div>
          {gameScreenshots.description && (
            <p className="py-3">{gameScreenshots.description}</p>
          )}
          <SwiperComponent
            showCards={showCards}
            onTap={({ activeIndex, clickedIndex, realIndex }) => {
              if (activeIndex === clickedIndex) {
                setExpandedImageIndex(realIndex)
                setIsExpanded(true)
              }
            }}
          >
            {gameScreenshots.images.map(image => (
              <SwiperSlide key={image.id}>
                <div className="image-container">
                  <SiteImage
                    width="474px"
                    height="316px"
                    image={image}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            ))}
          </SwiperComponent>
          {isExpanded && (
            <div
              className="expanded-slide-container"
              onClick={() => {
                setIsExpanded(false)
              }}
            >
              {expandedImageIndex !== null && (
                <div className="expanded-image-container">
                  <SiteImage
                    width="474px"
                    height="316px"
                    image={gameScreenshots.images[expandedImageIndex]}
                    alt=""
                  />
                  <CloseIcon />
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    )
  )
}
